declare module 'luxon' {
   interface TSSettings {
      throwOnInvalid: true;
   }
}

import { Settings } from 'luxon';
import { z } from 'zod';
import { extendZodWithOpenApi } from '@anatine/zod-openapi';

Settings.throwOnInvalid = true;

// There's a problem with doing this here in index.js for web (NextJS) apps.
//
// Related issue might be: https://github.com/vercel/next.js/issues/64403
// When using proposed fix (serverComponentsExternalPackages), the error is:
// Error: The packages specified in the 'transpilePackages' conflict with the 'serverComponentsExternalPackages': @innerwell/dtos
// at getBaseWebpackConfig (/Users/bero/Workspace/innerwell/node_modules/next/dist/build/webpack-config.js:639:19)
// at /Users/bero/Workspace/innerwell/node_modules/next/dist/build/webpack-build/impl.js:151:40
// at async Span.traceAsyncFn (/Users/bero/Workspace/innerwell/node_modules/next/dist/trace/trace.js:154:20)
// at async webpackBuildImpl (/Users/bero/Workspace/innerwell/node_modules/next/dist/build/webpack-build/impl.js:136:21)
// at async /Users/bero/Workspace/innerwell/node_modules/next/dist/build/index.js:1003:111
// at async Span.traceAsyncFn (/Users/bero/Workspace/innerwell/node_modules/next/dist/trace/trace.js:154:20)
// at async build (/Users/bero/Workspace/innerwell/node_modules/next/dist/build/index.js:366:9)
//
extendZodWithOpenApi(z);

export * from './app';
export * from './formsort';
export * from './payments';
export * from './therapy-plans';
export * from './patients';
export * from './zoom';
export * from './auth';
export * from './welkin';
export * from './appointments';
export * from './scheduling';
export * from './blog';
export * from './clinicians';
export * from './mood-tracking';
export * from './errors';
export * from './cognito';
export * from './common';
export * from './customerio';
export * from './ads-counter';
export * from './products';
export * from './nmi';
export * from './facebook-conversion';
export * from './sendbird';
export * from './magento';
export * from './cart';
export * from './playlists';
export * from './timetap';
export * from './states';
export * from './encounters';
export * from './service-provider-locations';
export * from './insurance';
export * from './followons';
export * from './precision-prescriptions';
export * from './program-switch';
export * from './chat';
export * from './appointment-bank';
export * from './missed-appointment';
export * from './cancel-appointment';
export * from './games';
export * from './services';
export * from './treatment-methods';
export * from './marketing';
export * from './users-admin';
export * from './events';
export * from './sohar';
export * from './precision';
export * from './data-driven-care';
export * from './admin-impersonation';
